<template>
  <BaseSimpleLayout class="master-product-list">
    <template #title>製品マスタ一覧</template>
    <template v-if="loadingFlag" #button-area>
      <el-input
        v-model="searchText"
        size="large"
        placeholder="検索"
        @input="searchData"
      />
      <el-button
        v-if="createRole"
        class="unit-master-product-list-button-create"
        type="success"
        size="large"
        @click="router.push('/master/product/create')"
      >
        製品登録
      </el-button>
    </template>
    <template v-if="loadingFlag" #main>
      <el-table :data="tableData" border :max-height="tableHeight">
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column prop="name" label="名前" sortable>
          <template #default="scope">
            <a href="#" @click="moveDetail(scope.row.id)">{{
              scope.row.name
            }}</a>
          </template>
        </el-table-column>
        <el-table-column
          prop="productCode"
          label="品番"
          width="120"
          align="center"
          sortable
        />
        <el-table-column
          prop="jan"
          label="JAN"
          width="120"
          align="center"
          sortable
        />
        <el-table-column
          prop="productType"
          label="種別"
          width="150"
          align="center"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.productType ? productType[scope.row.productType] : '-'
            }}
          </template>
        </el-table-column>
        <el-table-column
          prop="price"
          label="費用"
          width="120"
          align="center"
          sortable
        />
        <el-table-column
          prop="deviceType"
          label="MCCS ver"
          width="120"
          align="center"
          sortable
        >
          <template #default="scope">
            {{
              scope.row.deviceType != null
                ? deviceType[Number(scope.row.deviceType)] || '-'
                : '-'
            }}
          </template>
        </el-table-column>
      </el-table>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { ProductsDataState } from '@/types/master/product';
  import { productType, deviceType } from '@/libs/product';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';

  const store = useStore();
  const tableData = ref<ProductsDataState[]>([]);
  const loadingFlag = ref(false);
  const createRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));
  const tableHeight = ref(600);
  const searchText = ref('');

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('productsMaster/fetch');
    tableData.value = store.state.productsMaster.listData;
    tableHeight.value = window.innerHeight - 120;
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const moveDetail = (id: number) => {
    router.push('/master/product/' + id);
  };
  const searchData = () => {
    tableData.value = store.state.productsMaster.listData.filter(
      (item: ProductsDataState) => {
        if (!searchText.value) return true;
        if (item.name.includes(searchText.value)) return true;
        if (item.price.toString().includes(searchText.value)) return true;
        const product = productType[item.productType];
        if (product.includes(searchText.value)) return true;
        if (item.deviceType?.toString().includes(searchText.value)) return true;
      }
    );
  };
  initialize();
</script>
<style lang="scss">
  .master-product-list {
    .el-input {
      width: 300px;
      margin: 0 20px;
    }
    .el-table {
      width: 950px;
      margin: 10px auto 0;
      thead {
        th {
          background: #f5f7fa;
        }
      }
    }
    .el-button {
      font-weight: 600;
    }
  }
</style>
