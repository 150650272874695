<template>
  <div v-if="loadingFlag" class="device-label">
    <div class="button-area">
      印刷の際は「余白」を「なし」に設定の上、印刷してください。<br />
      <el-button size="large" type="info" @click="router.push('/device/11')">
        戻る
      </el-button>
      <el-button size="large" type="primary" @click="handlePrint">
        印刷
      </el-button>
      <span class="range-label">印刷位置</span>
      <el-input-number
        v-model="startPosition"
        :controls="false"
        :min="1"
        :max="65"
        class="start-position"
        @change="onPositionChange"
      />
      <span class="range-label">枚数</span>
      <el-input-number
        v-model="piece"
        :controls="false"
        :min="1"
        :max="5"
        class="piece-input"
        @change="onPositionChange"
      />
      <span class="range-label">件数</span>
      <el-input-number
        v-model="limit"
        :controls="false"
        :min="1"
        :max="500"
        class="limit-input"
        @change="onPositionChange"
      />
    </div>
    <section class="sheet">
      <div class="paper-padding">
        <!-- ラベルのコンテナ -->
        <div class="label-container">
          <!-- 各ラベルを繰り返し表示 -->
          <span v-for="data in outData" :key="data.serialCode">
            <div class="one-label">
              <img v-if="data.serialCode" src="@/assets/logo_gms_h_jp.jpg" />
              <div>
                <ul>
                  <li v-if="data.serialCode" class="text-left">
                    S/N {{ data.serialCode }}
                  </li>
                </ul>
              </div>
            </div>
          </span>
        </div>
      </div>
    </section>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import { ElLoading } from 'element-plus';
  import { error } from '@/libs/notification';

  const store = useStore();
  const outData = ref<
    {
      serialCode: string;
      IMEI: string | null;
    }[]
  >([]);
  const loadingFlag = ref(false);
  const startPosition = ref(1);
  const piece = ref(2);
  const limit = ref(100);

  const fetchDeviceLabel = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    try {
      outData.value = [];
      await store.dispatch('devices/getDeviceLabel', {
        startPosition: startPosition.value,
        piece: piece.value,
        limit: limit.value
      });
      const labelData = store.state.devices.labelData;

      if (!labelData.data) {
        router.push('/device/11');
        return;
      }

      // データを変換して格納
      outData.value = labelData.data.map(
        (data: {
          id: number | null;
          serialCode: string | null;
          IMEI: string | null;
        }) => ({
          id: data.id || null,
          serialCode: data.serialCode || '',
          IMEI: data.IMEI || ''
        })
      );
    } catch (e) {
      error('400', '未印刷のデータは見つかりませんでした');
      router.push('/device/11');
    } finally {
      // ローディング終了
      loadingInstance.close();
      loadingFlag.value = true;
    }
  };

  const handlePrint = () => {
    window.print();
    store.dispatch(
      'devices/patchDeviceLabel',
      store.state.devices.labelData.idArray
    );
  };

  const onPositionChange = fetchDeviceLabel;

  // 初期化
  fetchDeviceLabel();
</script>

<style lang="scss">
  .device-label {
    @media print {
      .sheet {
        zoom: 150%;
        margin: 0;
        padding: 0;
        box-shadow: none;
      }

      #content {
        float: none;
        width: 100%;
      }

      .button-area {
        display: none;
      }
    }

    .button-area {
      margin-top: 20px;
    }

    .el-button {
      span {
        color: #ffffff;
        font-weight: 600;
      }
    }

    * {
      color: #000000;
      font-family: '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック体',
        YuGothic, sans-serif;
      font-size: 12pt;
      font-weight: bold;
      -webkit-print-color-adjust: exact;
      print-color-adjust: exact;
    }

    .select {
      padding-left: 20px;
    }

    ul {
      list-style: none;
      padding-left: 0;
    }

    .sheet {
      overflow: hidden;
      position: relative;
      height: 294mm; /* A4 height */
      width: 210mm; /* A4 width */
      background: #ffffff;
      box-shadow: 0 0.5mm 2mm rgba(0, 0, 0, 0.3);
      margin: 1mm auto;
    }

    .paper-padding {
      padding-top: 10mm;
      padding-left: 7mm;
      padding-right: 2.5mm;
      padding-bottom: 0mm;
    }

    .text-left {
      text-align: left;
    }

    .text-center {
      text-align: center;
    }

    .text-right {
      text-align: right;
    }

    .label-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr); /* 5列 */
      grid-template-rows: repeat(13, auto); /* 13行 */
      grid-column-gap: 2mm; /* 横方向の隙間を2mmに設定 */
      grid-row-gap: 0.1mm; /* 縦方向の隙間は5mmに設定 */
    }

    .one-label {
      width: 38mm; /* ラベルの幅 */
      height: 21mm; /* ラベルの高さ */
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;
      position: relative;
      // margin-left: 1mm;
    }

    .one-label img {
      height: 60px;
      margin-left: 30px;
      margin-top: 2.7mm;
      opacity: 0.35;
      position: absolute;
    }

    .one-label div {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: auto;
      margin-right: auto;
    }

    .one-label li {
      margin-left: 5px;
      text-align: left;
      margin-top: -10px;
    }

    .start-position {
      width: 50px;
    }

    .limit-input {
      width: 60px;
    }

    .piece-input {
      width: 60px;
    }

    .range-label {
      margin-left: 30px;
      margin-right: 10px;
      font-weight: bold;
    }
  }
</style>
