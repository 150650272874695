/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from 'axios';
import { error, success } from '@/libs/notification';
import router from '@/router';
import {
  DevicesState,
  DevicesDataState,
  DevicesFilterData,
  DeviceSimRegistData,
  DevicesLabelData,
  DevicesLabelFilterData
} from '@/types/device';
import { ElLoading } from 'element-plus';
import { handleAuthorizationError } from '@/libs/apiErrorHandler';
import { getAccessToken } from '@/libs/firebaseAuth';
import { getBaseUrl } from '@/libs/env';

export default {
  namespaced: true,
  state: {
    listData: [],
    readyListData: [],
    filterData: null,
    labelData: []
  },
  mutations: {
    setList(state: DevicesState, payload: DevicesDataState[]) {
      state.listData = payload;
    },
    setReadyList(state: DevicesState, payload: DevicesDataState[]) {
      state.readyListData = payload;
    },
    setFilter(state: DevicesState, payload: DevicesFilterData) {
      state.filterData = payload;
    },
    setLabel(state: DevicesState, payload: DevicesLabelData[]) {
      state.labelData = payload;
    }
  },
  actions: {
    async fetch(context: any, MCCSver: number) {
      const headers: any = {
        auth: await getAccessToken()
      };
      if (MCCSver) {
        headers.deviceType = MCCSver;
      }
      await axios
        .get(getBaseUrl() + '/device', {
          headers: headers
        })
        .then((response) => {
          const sortData = response.data.sort(
            (a: DevicesDataState, b: DevicesDataState) => {
              if (a.id > b.id) {
                return -1;
              }
              return 1;
            }
          );
          context.commit('setList', sortData);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async fetchReady(context: any) {
      await axios
        .get(getBaseUrl() + '/device', {
          params: {
            status: [2]
          },
          headers: { auth: await getAccessToken() }
        })
        .then((response) => {
          context.commit('setReadyList', response.data);
        })
        .catch(async (e) => {
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status < 500) {
            error(e.response.status);
            router.push('/top');
          } else {
            router.push('/error/server');
          }
        });
    },
    async simRegister(context: any, payload: DeviceSimRegistData) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await axios
        .post(getBaseUrl() + '/device/sim', payload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', 'simの登録に成功しました。');
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          }
          if (e.response.status === 405) {
            const errorIdList = e.response.data.map(
              (error: any) => '\r\n' + error.sim
            );
            error(
              e.response.status,
              '次のsimの登録に失敗しました' + errorIdList
            );
          } else if (e.response.status < 500) {
            error(e.response.status);
            router.push('/device');
          } else {
            router.push('/error/server');
          }
        });
    },
    async setFilterData(context: any, payload: DevicesFilterData) {
      context.commit('setFilter', payload);
    },
    async getDeviceLabel(context: any, payload: DevicesLabelFilterData) {
      try {
        const { startPosition, piece, limit } = payload;

        const response = await axios.get(`${getBaseUrl()}/device/label`, {
          params: {
            startPosition: startPosition,
            piece: piece,
            limit: limit
          },
          headers: { auth: await getAccessToken() }
        });

        context.commit('setLabel', response.data);
      } catch (error) {
        const status = (error as any).response?.status;
        console.error('Error fetching device label:', error);

        if (status === 400 || status === 404) {
          await handleAuthorizationError();
        } else if (status && status < 500) {
          router.push('/top');
        } else {
          router.push('/error/server');
        }
      }
    },
    async patchDeviceLabel(
      context: any,
      payload: { deviceLedgerIds: number[] }
    ) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      const requestPayload = {
        deviceLedgerIds: payload
      };
      console.log(requestPayload);
      await axios
        .patch(getBaseUrl() + '/device/label', requestPayload, {
          headers: { auth: await getAccessToken() }
        })
        .then(() => {
          success('Success', 'ラベル発行済みに更新しました');
          loadingInstance.close();
        })
        .catch(async (e) => {
          loadingInstance.close();
          if (e.response.status === 401) {
            await handleAuthorizationError();
          } else if (e.response.status < 500) {
            error(e.response.status);
          } else {
            router.push('/error/server');
          }
        });
    },
    async csvImport(context: any, payload: any) {
      const chunkSize = 50; // 一度に送る数
      const totalChunks = Math.ceil(payload.length / chunkSize); // 送信回数

      const loadingInstance = ElLoading.service({ fullscreen: true });
      for (let i = 0; i < totalChunks; i++) {
        const chunk = payload.slice(i * chunkSize, (i + 1) * chunkSize);
        await axios
          .post(getBaseUrl() + '/device/csv-import', chunk, {
            headers: { auth: await getAccessToken() }
          })
          .then(() => {
            console.log('success...');
          })
          .catch(async (e) => {
            loadingInstance.close();
            if (e.response && e.response.status === 401) {
              await handleAuthorizationError();
            } else if (e.response && e.response.status < 500) {
              error(e.response.status);
            } else {
              router.push('/error/server');
            }
            return;
          });
      }
      loadingInstance.close();
      success(
        'Success',
        'CSV取り込みに成功しました。全てのデータがサーバーに送信されました。'
      );
    }
  }
};
