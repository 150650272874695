<template>
  <BaseSimpleLayout class="device-list">
    <template #title>
      <span
        :style="{ backgroundColor: MCCSver === 2 ? 'yellow' : 'transparent' }"
      >
        MCCS{{
          MCCSver === 2
            ? 'Ⅱ'
            : MCCSver === 3
            ? 'Ⅲ'
            : MCCSver === 11
            ? ' Type T'
            : ''
        }}
        端末一覧
      </span>
    </template>
    <template v-if="loadingFlag" #button-area>
      <el-button
        v-if="shipAdminRole && MCCSver !== 11"
        type="success"
        size="large"
        @click="simRegisterDialog = true"
      >
        SIM登録
      </el-button>
      <template v-else-if="shipAdminRole && MCCSver === 11">
        <input
          type="file"
          ref="fileInput"
          @change="handleFileSelect"
          accept=".csv"
        />
        <el-button type="success" size="large" @click="csvImport">
          CSV取込
        </el-button>
        <el-button
          type="primary"
          size="large"
          @click="router.push(`/device/label`)"
        >
          ラベル発行
        </el-button>
      </template>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="device-list-filter-area">
        <span class="filter-label sim-label">SIM番号</span>
        <span class="serial-label">開始:</span>
        <el-input
          v-model="filterForm.simStart"
          clearable
          placeholder="入力"
          class="serial-number"
          maxlength="14"
          @input="filterDevice"
        />
        <span class="serial-label">終了:</span>
        <el-input
          v-model="filterForm.simEnd"
          clearable
          placeholder="入力"
          class="serial-number"
          maxlength="14"
          @input="filterDevice"
        />
        <span class="filter-label">フィルター</span>
        <el-input
          v-model="filterForm.text"
          clearable
          placeholder="検索"
          @input="filterDevice"
        />
        <template v-if="MCCSver !== 11">
          <span class="filter-label">確定端末</span>
          <el-select
            v-model="filterForm.orderCompany"
            filterable
            clearable
            placeholder="選択"
            class="order-company-select-list"
            @change="filterDevice"
          >
            <el-option
              v-for="item in filterCompanyList"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            />
          </el-select>
        </template>
        <span class="filter-label">件数</span>
        <el-input-number
          class="form-count"
          v-model="filterForm.count"
          :min="1"
          :max="10000"
          :controls="false"
          :precision="0"
          @change="filterDevice"
        />
      </div>
      <div class="device-list-filter-area">
        <span class="filter-label">シリアル番号</span>
        <span class="serial-label">開始:</span>
        <el-input
          v-model="filterForm.serialStart"
          clearable
          placeholder="入力"
          class="serial-number"
          maxlength="16"
          @input="filterDevice"
        />
        <span class="serial-label">終了:</span>
        <el-input
          v-model="filterForm.serialEnd"
          clearable
          placeholder="入力"
          class="serial-number"
          maxlength="16"
          @input="filterDevice"
        />
        <span class="filter-label">ステータス</span>
        <el-checkbox-group v-model="filterForm.status" @change="filterDevice">
          <el-checkbox label="未設定" />
          <el-checkbox label="準備中" />
          <el-checkbox label="検証済" />
          <el-checkbox label="使用中" />
          <el-checkbox label="使用不可" />
          <el-checkbox label="稼働停止" />
        </el-checkbox-group>
      </div>
      <el-table
        :data="tableData"
        border
        :max-height="tableHeight"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          prop="id"
          label="ID"
          width="80"
          align="center"
          sortable
        />
        <el-table-column
          property="simNumber"
          label="SIM番号"
          align="center"
          width="180"
          sortable
        >
          <template #default="scope">
            <span v-if="scope.row.simNumber">{{ scope.row.simNumber }}</span>
            <span v-else>-</span>
          </template>
          <!-- <template #default="scope">
            <router-link
              v-if="scope.row.serialCode"
              :to="`/device/${scope.row.id}/detail`"
            >
              {{ scope.row.simNumber }}
            </router-link>
            <span v-else>
              {{ scope.row.simNumber }}
            </span>
          </template> -->
        </el-table-column>
        <el-table-column
          property="serialCode"
          label="シリアル番号"
          align="center"
          width="170"
          sortable
        >
          <template #default="scope">
            <router-link
              v-if="scope.row.serialCode"
              :to="`/device/${scope.row.id}/detail`"
            >
              {{ scope.row.serialCode }}
            </router-link>
            <template v-else>
              <el-button
                v-if="shipRole"
                size="small"
                type="primary"
                @click="
                  clickRegisterButton(
                    scope.row.id,
                    scope.row.simNumber,
                    scope.row.deviceType
                  )
                "
              >
                端末登録
              </el-button>
              <span v-else>-</span>
            </template>
          </template>
          <!-- <template #default="scope">
            <el-button
              v-if="!scope.row.serialCode && shipRole"
              size="small"
              type="primary"
              @click="
                clickRegisterButton(
                  scope.row.id,
                  scope.row.simNumber,
                  scope.row.deviceType
                )
              "
            >
              端末登録
            </el-button>
            <span v-if="!scope.row.serialCode && !shipRole">-</span>
            <span v-if="scope.row.serialCode">{{ scope.row.serialCode }}</span>
          </template> -->
        </el-table-column>
        <el-table-column
          property="status"
          label="ステータス"
          align="center"
          width="150"
          sortable
        >
          <template #default="scope">
            {{ deviceStatus[scope.row.status] }}
          </template>
        </el-table-column>
        <el-table-column
          property="shipId"
          label="出荷ID"
          align="center"
          width="120"
          sortable
        >
          <template #default="scope">
            {{ scope.row.shipId ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          property="handlingType"
          label="取扱"
          align="center"
          width="90"
          sortable
        >
          <template #default="scope">
            {{ handlingType[scope.row.handlingType] }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="MCCSver !== 11"
          property="orderCompanyName"
          label="確定端末"
          align="center"
          width="220"
          sortable
        >
          <template #default="scope">
            {{ scope.row.orderCompanyName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="MCCSver !== 11"
          property="firmVersion"
          label="ﾌｧｰﾑVer"
          align="center"
          width="110"
          sortable
        >
          <template #default="scope">
            {{ scope.row.firmVersion ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="MCCSver !== 11"
          property="configName"
          label="ｺﾝﾌｨｸﾞ"
          align="center"
          width="110"
          sortable
        >
          <template #default="scope">
            {{ scope.row.configName ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column
          v-if="MCCSver !== 11"
          property="internalBatteryVoltage"
          label="IBﾊﾞｯﾃﾘｰ"
          align="center"
          width="110"
          sortable
        >
          <template #default="scope">
            {{ scope.row.internalBatteryVoltage ?? '-' }}
          </template>
        </el-table-column>
        <el-table-column property="note" label="備考" align="center" sortable>
          <template #default="scope">
            {{ scope.row.note }}
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        v-model="dialogVisible"
        title="端末シリアル番号登録"
        width="550px"
      >
        <el-form
          ref="formRef"
          :model="serialForm"
          :rules="serialRules"
          size="large"
          label-width="170px"
        >
          <el-form-item label="ID">
            <el-input v-model="serialForm.id" disabled />
          </el-form-item>
          <el-form-item label="SIM番号">
            <el-input v-model="serialForm.simNumber" disabled />
          </el-form-item>
          <el-form-item label="端末タイプ" prop="deviceType">
            <el-select
              v-model="serialForm.deviceType"
              placeholder=""
              @change="changeSerialInitial"
            >
              <el-option
                v-for="item in deviceTypeList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="マイナーVer." prop="deviceMinorVersion">
            <el-select
              v-model="serialForm.deviceMinorVersion"
              placeholder=""
              :disabled="isDeviceMinorVersionDisabled"
              @change="changeSerialInitial"
            >
              <el-option
                v-for="item in deviceMinorVersionList"
                :key="item.name"
                :label="item.name"
                :value="item.name"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="シリアル番号" prop="serialCode">
            <el-input v-model="serialForm.serialInitial" disabled />
            <el-input
              v-model="serialForm.serialCode"
              @change="changeString('serialCode')"
            />
          </el-form-item>
          <el-form-item label="製造番号" prop="deviceCode">
            <el-input
              v-model="serialForm.deviceCode"
              @change="changeString('deviceCode')"
            />
          </el-form-item>
          <el-form-item label="確定端末" prop="orderCompanyId">
            <el-select
              v-model="serialForm.orderCompanyId"
              filterable
              clearable
              placeholder="選択"
            >
              <el-option
                v-for="item in companiesList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="備考" prop="note">
            <el-input v-model="serialForm.note" type="textarea" />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="prepareDevice(formRef)"
          >
            登録
          </el-button>
        </template>
      </el-dialog>
      <el-dialog v-model="simRegisterDialog" title="SIM一括登録" width="450px">
        <el-form
          ref="simFormRef"
          :model="simRegisterForm"
          :rules="simRules"
          size="large"
          label-width="110px"
        >
          <el-form-item label="番号選択">
            <el-radio-group v-model="simRegisterForm.deviceType">
              <el-radio :label="3">3</el-radio>
              <el-radio :label="2">2</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="開始番号" prop="fromSIMNo">
            <el-input
              v-model="simRegisterForm.fromSIMNo"
              @change="changeNumber('fromSIMNo')"
            />
          </el-form-item>
          <el-form-item label="終了番号" prop="toSIMNo">
            <el-input
              v-model="simRegisterForm.toSIMNo"
              @change="changeNumber('toSIMNo')"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="simRegisterDialog = false">
            キャンセル
          </el-button>
          <el-button
            type="primary"
            size="large"
            @click="registerSim(simFormRef)"
          >
            登録
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import { useRoute } from 'vue-router';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import {
    DevicesDataState,
    DevicesFilterData,
    DeviceSimRegistData,
    DeviceSerialRegistData
  } from '@/types/device';
  import { CompaniesDataState } from '@/types/master/company';
  import type { FormInstance, FormRules } from 'element-plus';
  import {
    deviceStatus,
    deviceTypeList,
    validateSerial,
    handlingType,
    deviceMinorVersionList,
    deviceItemId
  } from '@/libs/device';
  import {
    numberRestriction,
    alphanumericRestriction
  } from '@/libs/restriction';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import _ from 'lodash';

  let csvData: string | undefined;
  const route = useRoute();
  const MCCSver = ['2', '3', '11'].includes(
    Array.isArray(route.params.MCCSver)
      ? route.params.MCCSver[0]
      : route.params.MCCSver
  )
    ? Number(route.params.MCCSver)
    : (router.push('/accept'), 0);

  const MCCSⅢ_Ini = 'MS03J';
  const store = useStore();
  const formRef = ref<FormInstance>();
  const isDeviceMinorVersionDisabled = ref(false);
  const simFormRef = ref<FormInstance>();
  const tableData = ref<DevicesDataState[]>([]);
  const filterForm = ref<DevicesFilterData>({
    simStart: '',
    simEnd: '',
    serialStart: '',
    serialEnd: '',
    text: '',
    status: ['未設定', '準備中'],
    orderCompany: '',
    count: 100,
    note: ''
  });
  const dialogVisible = ref(false);
  const simRegisterDialog = ref(false);
  const serialForm = ref<DeviceSerialRegistData>({
    id: 0,
    simNumber: '',
    serialCode: '',
    deviceType: 3,
    deviceCode: '',
    orderCompanyId: null,
    note: '',
    serialInitial: '',
    deviceMinorVersion: 'J02'
  });
  const simRegisterForm = ref<DeviceSimRegistData>({
    fromSIMNo: null,
    toSIMNo: null,
    deviceType: 3,
    deviceMinorVersion: 'J02',
    serialInitial: MCCSⅢ_Ini + '2'
  });
  const serialRules = ref<FormRules>({
    serialCode: [
      { validator: validateSerial, trigger: 'blur' },
      {
        required: true,
        message: 'シリアル番号は必須です。',
        trigger: 'blur'
      }
    ],
    deviceType: [
      {
        required: true,
        message: 'MCCSverは必須です。'
      }
    ]
  });
  const simRules = ref<FormRules>({
    fromSIMNo: [
      { required: true, message: '開始番号は必須です。' },
      {
        min: 11,
        max: 14,
        message: '11-14桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ],
    toSIMNo: [
      { required: true, message: '終了番号は必須です。' },
      {
        min: 11,
        max: 14,
        message: '11-14桁の半角数字で入力してください。',
        trigger: 'blur'
      }
    ]
  });
  const companiesList = ref<CompaniesDataState[]>([]);
  const filterCompanyList = ref<CompaniesDataState[]>([]);
  const loadingFlag = ref(false);
  const shipRole = ref<boolean>(
    rolePermission(['SHIPPING', 'SHIPPING_PLUS', 'SHIPADMIN'])
  );
  const shipAdminRole = ref<boolean>(rolePermission(['SHIPADMIN']));
  const tableHeight = ref(600);

  const createTableData = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    store.dispatch('management/setCustomerIdData', null);
    await store.dispatch('devices/fetch', MCCSver);
    await store.dispatch('companiesMaster/fetch');
    tableHeight.value = window.innerHeight - 215;
    if (store.state.devices.filterData) {
      filterForm.value = store.state.devices.filterData;
    }
    // serialCodeで並び替え
    if (MCCSver === 11) {
      store.state.devices.listData.sort(
        (a: DevicesDataState, b: DevicesDataState) => {
          return a.serialCode.localeCompare(b.serialCode);
        }
      );
    }
    filterDevice();
    companiesList.value = store.state.companiesMaster.listData.filter(
      (data: CompaniesDataState) => data.isAdvanceOrder
    );
    filterCompanyList.value = _.cloneDeep(companiesList.value);
    filterCompanyList.value.push({
      id: 0,
      name: '通常端末',
      prefecture: '',
      isAdvanceOrder: true
    });
    loadingInstance.close();
    loadingFlag.value = true;
  };

  const filterDevice = () => {
    let count = 0;
    store.dispatch('devices/setFilterData', filterForm.value);
    tableData.value = store.state.devices.listData.filter(
      (data: DevicesDataState) => {
        if (count >= filterForm.value.count) return false;
        // let serial = 0;
        let serial = '';
        const sim = data.simNumber ? Number.parseInt(data.simNumber) : 0;
        filterForm.value.simStart = filterForm.value.simStart.replace(
          /[^\d]/g,
          ''
        );
        filterForm.value.simEnd = filterForm.value.simEnd.replace(/[^\d]/g, '');
        // filterForm.value.serialStart = filterForm.value.serialStart.replace(
        //   /[^\d]/g,
        //   ''
        // );
        // filterForm.value.serialEnd = filterForm.value.serialEnd.replace(
        //   /[^\d]/g,
        //   ''
        // );
        // if (data.status) serial = Number.parseInt(data.serialCode);
        if (data.status) serial = data.serialCode;
        const status = deviceStatus[data.status];
        if (!filterForm.value.status.includes(status)) return false;
        if (filterForm.value.simStart) {
          if (Number.parseInt(filterForm.value.simStart) > sim) {
            return false;
          }
        }
        if (filterForm.value.simEnd) {
          if (Number.parseInt(filterForm.value.simEnd) < sim) {
            return false;
          }
        }
        if (filterForm.value.serialStart) {
          if (!serial) return false;
          // if (Number.parseInt(filterForm.value.serialStart) > serial) {
          //   return false;
          // }
          if (filterForm.value.serialStart > serial) {
            return false;
          }
        }
        if (filterForm.value.serialEnd) {
          if (!serial) return false;
          // if (Number.parseInt(filterForm.value.serialEnd) < serial) {
          //   return false;
          // }
          if (filterForm.value.serialEnd < serial) {
            return false;
          }
        }
        if (filterForm.value.orderCompany) {
          const companyName = data.orderCompanyName ?? '通常端末';
          if (companyName !== filterForm.value.orderCompany) return false;
        }
        if (filterForm.value.text) {
          const shipId = data.shipId ? data.shipId.toString() : '';
          const internalBatteryVoltage = data.internalBatteryVoltage ?? '';
          const handling = handlingType[data.handlingType];
          const serialCode = data.serialCode ?? '';
          const simNumber = data.simNumber ?? '';
          const note = data.note ?? '';
          if (
            shipId.includes(filterForm.value.text) ||
            internalBatteryVoltage.includes(filterForm.value.text) ||
            handling.includes(filterForm.value.text) ||
            serialCode.includes(filterForm.value.text) ||
            note.includes(filterForm.value.text) ||
            simNumber.includes(filterForm.value.text)
          ) {
            count += 1;
            return true;
          } else {
            return false;
          }
        }
        count += 1;
        return true;
      }
    );
  };

  const clickRegisterButton = (id: number, sim: string, deviceType: number) => {
    serialForm.value.id = id;
    serialForm.value.simNumber = sim;
    serialForm.value.serialCode = '';
    serialForm.value.deviceCode = '';
    serialForm.value.orderCompanyId = null;
    serialForm.value.note = '';
    serialForm.value.deviceType = deviceType;
    if (deviceType === 3) {
      serialForm.value.deviceMinorVersion = 'J02';
      serialForm.value.serialInitial = MCCSⅢ_Ini + '2';
      isDeviceMinorVersionDisabled.value = false;
    } else {
      serialForm.value.serialInitial = '';
      serialForm.value.deviceMinorVersion = null;
      isDeviceMinorVersionDisabled.value = true;
    }
    serialForm.value.deviceype;
    dialogVisible.value = true;
  };

  const prepareDevice = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    await formEl.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      dialogVisible.value = false;
      await store.dispatch('device/prepare', {
        id: serialForm.value.id,
        serialCode:
          serialForm.value.serialInitial + serialForm.value.serialCode,
        deviceCode: serialForm.value.deviceCode,
        deviceType: serialForm.value.deviceType,
        deviceMinorVersion: serialForm.value.deviceMinorVersion
          ? serialForm.value.deviceMinorVersion
          : '',
        itemId: serialForm.value.deviceMinorVersion
          ? deviceItemId[serialForm.value.deviceMinorVersion]
          : null,
        orderCompanyId: serialForm.value.orderCompanyId,
        note: serialForm.value.note
      });
      createTableData();
    });
  };

  const handleFileSelect = (event: Event) => {
    csvData = '';
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        const content = (event?.target?.result as string) || '';
        // ダブルクォートで囲まれた項目内の改行を削除する
        const contentWithoutNewLinesInQuotes = content.replace(
          /"([^"]*)"/g,
          (match, p1) => {
            return `"${p1.replace(/\r\n|\n|\r/g, ' ')}"`;
          }
        );
        csvData = contentWithoutNewLinesInQuotes;
      };
      reader.readAsText(file);
    }
  };

  const csvImport = async () => {
    if (!csvData) {
      return;
    }
    const lines = csvData.split('\n');
    const header = lines[0].replace(/\r/g, '').split(',');
    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);
      if (data) {
        const obj: {
          [key: string]: string;
        } = {};
        for (let j = 0; j < header.length; j++) {
          if (data[j]) {
            obj[header[j]] = data[j].replace(/"|\r\n|\n|\r/g, '');
          } else {
            obj[header[j]] = '';
          }
        }
        jsonData.push(obj);
      }
    }

    try {
      await store.dispatch('devices/csvImport', jsonData);
      createTableData();
    } catch (error) {
      console.error('ファイルのアップロードエラー:', error);
    }
  };

  const registerSim = async (simFormRef: FormInstance | undefined) => {
    if (!simFormRef) return;
    await simFormRef.validate(async (valid) => {
      if (!valid) {
        return false;
      }
      simRegisterDialog.value = false;
      await store.dispatch('devices/simRegister', simRegisterForm.value);
      createTableData();
    });
  };

  const changeNumber = (key: string) => {
    const data = simRegisterForm.value[key] as string;
    if (!data) return;
    simRegisterForm.value[key] = numberRestriction(data);
  };
  const changeString = (key: string) => {
    const data = serialForm.value[key];
    if (!data) return;
    serialForm.value[key] = alphanumericRestriction(data.toString());
  };
  const changeSerialInitial = () => {
    const { deviceType, deviceMinorVersion } = serialForm.value;

    serialForm.value.serialInitial = '';

    if (deviceType === 3) {
      isDeviceMinorVersionDisabled.value = false;
      serialForm.value.serialInitial = MCCSⅢ_Ini;

      switch (deviceMinorVersion) {
        case 'J01':
          serialForm.value.serialInitial += 1;
          break;
        case 'J02':
          serialForm.value.serialInitial += 2;
          break;
        default:
          serialForm.value.deviceMinorVersion = 'J02';
          serialForm.value.serialInitial += 2;
          break;
      }
    } else {
      isDeviceMinorVersionDisabled.value = true;
      serialForm.value.deviceMinorVersion = '';
    }
  };
  const tableRowClassName = ({
    row,
    rowIndex
  }: {
    row: DevicesDataState;
    rowIndex: number;
  }) => {
    if (row.status > 3) {
      return 'danger-row';
    }
    if (rowIndex % 2 !== 0) {
      return 'light-row';
    }
    return '';
  };
  createTableData();
</script>
<style lang="scss">
  .device-list {
    .main-container {
      width: 98%;
    }
    .device-list-filter-area {
      float: left;
      margin: 5px 0px 10px 0px;
      .el-input {
        width: 200px;
      }
      .form-count {
        width: 70px;
        .el-input {
          width: 70px;
        }
      }
      .serial-number {
        width: 150px;
      }
      .order-company-select-list {
        .el-input {
          width: 250px;
        }
      }
    }
    .filter-label {
      margin: 0px 10px 0px 20px;
    }
    .sim-label {
      margin-left: 56px;
    }
    .serial-label {
      margin: 0px 10px 0px 5px;
      font-size: 14px;
    }
    .el-checkbox-group {
      display: initial;
    }
    .el-checkbox {
      font-weight: 600;
    }
    .el-form {
      width: 450px;

      .el-input.is-disabled .el-input__inner {
        color: #606266;
      }
      .el-input {
        width: 200px;
        float: left;
      }
      .el-select {
        width: 200px;
        float: left;
      }
    }
    .dialog-title {
      font-weight: 600;
    }
    .el-button {
      font-weight: 600;
    }
    .el-table {
      margin: 10px auto;
      thead th {
        background: #f5f7fa;
      }
      .danger-row {
        --el-table-tr-bg-color: var(--el-color-danger-light-9);
      }
      .light-row {
        --el-table-tr-bg-color: var(--el-fill-color-lighter);
      }
    }
  }
</style>
