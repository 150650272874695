<template>
  <div v-if="loadingFlag" class="ship-check-list">
    <div class="sheets">
      <div class="sheet">
        <h2>出荷チェックリスト: {{ shipId }} ({{ today }})</h2>
        <el-table :data="tableData" stripe border>
          <el-table-column label="項目名" prop="label" width="186px" />
          <el-table-column label="データ" prop="value" width="560px" />
          <el-table-column
            label="確認A"
            prop="checkA"
            width="70px"
            align="left"
          />
          <el-table-column
            label="確認B"
            prop="checkB"
            width="70px"
            align="left"
            class-name="last"
          />
        </el-table>
      </div>
      <div class="button-area">
        <el-button
          size="large"
          type="info"
          @click="router.push('/ship/' + shipId)"
        >
          戻る
        </el-button>
        <el-button size="large" type="primary" @click="handlePrint">
          印刷
        </el-button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import router from '@/router';
  import { useStore } from 'vuex';
  import { formatDate } from '@/libs/dateFormat';
  import { ElLoading } from 'element-plus';
  import { ignitionType } from '@/libs/accept';
  import { getPrintName } from '@/libs/ship';
  import { ShipDetailDataState } from '@/types/ship';
  import { deviceType, getHarnessByDeviceType } from '@/libs/device';

  const props = defineProps({
    shipId: String
  });
  const store = useStore();
  const tableData = ref<
    {
      label: string;
      value: string;
      checkA: string;
      checkB: string;
    }[]
  >([]);
  const loadingFlag = ref(false);
  const today = ref(formatDate(Date.now()));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('ship/fetchDetail', props.shipId),
      store.dispatch('itemsMaster/fetch'),
      store.dispatch('harnessesMaster/fetch')
    ]);
    const detailData = store.state.ship.detailData;
    if (!detailData) return router.push('/ship');
    let harnessName = '-';
    if (detailData.harnessId) {
      const item = await getHarnessByDeviceType(
        store.state.harnessesMaster.listData,
        detailData.harnessId,
        detailData.deviceType
      );
      if (item?.itemName) {
        harnessName = item.itemName;
      }
    } else {
      harnessName = 'キーカット';
    }
    const MCCSver = detailData.deviceType;
    let deviceList = '';
    for (let i = 0; i < detailData.devices.length; i++) {
      deviceList = deviceList + detailData.devices[i].deviceSerialCode;
      if (i === 4) deviceList = deviceList + '\r\n';
      else if (i === detailData.devices.length - 1)
        deviceList = deviceList + '';
      else deviceList = deviceList + ',';
    }

    let packageList = '';
    detailData.details.forEach(
      (pack: ShipDetailDataState) =>
        (packageList = packageList + pack.listName + ` ✖️ ${pack.qty}` + '\n')
    );

    let dataType = null;
    switch (detailData.businessType) {
      case 1: // 金融
        switch (detailData.deviceType) {
          case 2:
            dataType = 'financeMccs2';
            break;
          case 3:
          case 4:
            dataType = detailData.customerCode.startsWith('USS')
              ? 'ussMccs3'
              : 'financeMccs3';
            break;
          case 11:
            dataType = 'financeMccsT';
            break;
          default:
            break;
        }
        break;
      case 2: // カーセキュリティ
        switch (detailData.deviceType) {
          case 2:
            dataType = 'sbMccs2';
            break;
          case 3:
          case 4:
            dataType = 'sbMccs3';
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    type TableItemValue = string | string[] | null; // stringまたはstring[]またはnullを許可
    type TableItem = {
      label: string;
      value: TableItemValue; // valueはTableItemValue型
      checkA: string;
      checkB: string;
    };

    type TableItems = Record<string, TableItem>;

    const tableItems: TableItems = {
      customerCode: {
        label: '顧客管理番号',
        value: addTableData(detailData.customerCode) || '',
        checkA: '',
        checkB: ''
      },
      financeName: {
        label: 'ファイナンス',
        value: addTableData(detailData.financeName) || '',
        checkA: '',
        checkB: ''
      },
      orderCompanyName: {
        label: '確定端末',
        value: addTableData(detailData.orderCompanyName) || '',
        checkA: '',
        checkB: ''
      },
      deviceList: {
        label: 'シリアル番号',
        value: addTableData(deviceList) || '',
        checkA: '',
        checkB: ''
      },
      carMakerName: {
        label: 'メーカー',
        value: addTableData(detailData.carMakerName) || '',
        checkA: '',
        checkB: ''
      },
      carType: {
        label: '車名',
        value: addTableData(detailData.carType) || '',
        checkA: '',
        checkB: ''
      },
      carModel: {
        label: '型式',
        value: addTableData(detailData.carModel) || '',
        checkA: '',
        checkB: ''
      },
      vin: {
        label: '車台番号',
        value: addTableData(detailData.vin) || '',
        checkA: '',
        checkB: ''
      },
      deviceType: {
        label: 'MCCSver',
        value: deviceType[MCCSver] || '',
        checkA: '',
        checkB: ''
      },
      packageList: {
        label: 'パッケージ名称',
        value: packageList || '',
        checkA: '',
        checkB: ''
      },
      harnessName: {
        label: 'ハーネス名称',
        value: harnessName || '',
        checkA: '',
        checkB: ''
      },
      printName: {
        label: '印刷資料',
        value: getPrintName(harnessName, detailData) || '',
        checkA: '',
        checkB: ''
      },
      shipName: {
        label: '発送先店名',
        value: detailData.shipName || '',
        checkA: '',
        checkB: ''
      },
      arrivalDate: {
        label: '着指定日',
        value: detailData.arrivalDate
          ? formatDate(detailData.arrivalDate)
          : '-',
        checkA: '',
        checkB: ''
      },
      acceptNote: {
        label: '受注備考情報',
        value: addTableData(detailData.acceptNote) || '',
        checkA: '',
        checkB: ''
      },
      note: {
        label: '出荷備考情報',
        value: addTableData(detailData.note) || '',
        checkA: '',
        checkB: ''
      },
      compatibilityResultJudge: {
        label: '適合判定',
        value: detailData.compatibilityResultJudge === 2 ? '〇※' : '-',
        checkA: '',
        checkB: ''
      },
      ignitionType: {
        label: 'E/Gスタート方式',
        value: ignitionType[detailData.ignitionType],
        checkA: '',
        checkB: ''
      },

      shipAddressSub: {
        label: '住所サブ',
        value: addTableData(detailData.shipAddressSub) || '',
        checkA: '',
        checkB: ''
      },
      emblem: {
        label: 'エンブレム',
        value: '-',
        checkA: '',
        checkB: ''
      },
      qrPaper: {
        label: 'QR用紙',
        value: '-',
        checkA: '',
        checkB: ''
      },
      orderNumber: {
        label: '発注番号',
        value: addTableData(detailData.orderNumber) || '',
        checkA: '',
        checkB: ''
      },
      packageLabel: {
        label: 'パッケージラベル',
        value: '-',
        checkA: '',
        checkB: ''
      },
      mountAppPaper: {
        label: '取付アプリ用紙',
        value: '-',
        checkA: '',
        checkB: ''
      },
      MSPFCheck: {
        label: 'MSPF確認',
        value: '-',
        checkA: '',
        checkB: '不要'
      }
    };

    let tableLayout: string[] = []; // tableLayoutの型をstring[]に指定

    if (dataType === 'financeMccs2') {
      tableLayout = [
        'orderCompanyName',
        'deviceList',
        'deviceType',
        'packageList',
        'vin',
        'carMakerName',
        'carType',
        'carModel',
        'printName',
        'compatibilityResultJudge',
        'customerCode',
        'shipName',
        'arrivalDate',
        'acceptNote',
        'note'
      ];
    } else if (dataType === 'financeMccs3') {
      tableLayout = [
        'orderCompanyName',
        'deviceList',
        'deviceType',
        'packageList',
        'vin',
        'printName',
        'customerCode',
        'shipName',
        'arrivalDate',
        'acceptNote',
        'note'
      ];
    } else if (dataType === 'ussMccs3') {
      tableLayout = [
        'deviceList',
        'deviceType',
        'packageList',
        'vin',
        'printName',
        'shipName',
        'shipAddressSub',
        'arrivalDate',
        'acceptNote',
        'note'
      ];
    } else if (dataType === 'financeMccsT') {
      tableLayout = [
        'deviceList',
        'deviceType',
        'packageList',
        'vin',
        'MSPFCheck',
        'customerCode',
        'shipName',
        'arrivalDate',
        'acceptNote',
        'note'
      ];
    } else if (dataType === 'sbMccs2') {
      tableLayout = [
        'orderCompanyName',
        'deviceList',
        'deviceType',
        'packageList',
        'emblem',
        'qrPaper',
        'orderNumber',
        'packageLabel',
        'shipName',
        'arrivalDate',
        'acceptNote',
        'note'
      ];
    } else if (dataType === 'sbMccs3') {
      tableLayout = [
        'orderCompanyName',
        'deviceList',
        'deviceType',
        'packageList',
        'emblem',
        'mountAppPaper',
        'qrPaper',
        'orderNumber',
        'packageLabel',
        'shipName',
        'arrivalDate',
        'acceptNote',
        'note'
      ];
    }

    tableData.value = tableLayout.map((key) => {
      const item = tableItems[key];
      return {
        label: item.label,
        value: Array.isArray(item.value)
          ? item.value.join(', ')
          : item.value || '',
        checkA: item.checkA,
        checkB: item.checkB
      };
    });

    loadingInstance.close();
    loadingFlag.value = true;
  };
  const handlePrint = () => {
    window.print();
  };
  const addTableData = (text: string): string => {
    return text ?? '-';
  };
  initialize();
</script>
<style lang="scss">
  .ship-check-list {
    .sheets {
      .button-area {
        text-align: center;
        margin: 20px 10px;

        .el-button {
          font-weight: 600;
        }
      }

      .el-table {
        width: 889px;
        margin: auto;
        thead {
          color: #606266;
        }
        .cell {
          white-space: pre-wrap !important;
          font-weight: 600;
          font-size: 20px;
        }
      }

      .el-table__header-wrapper {
        th {
          background-color: #f5f7fa;
        }
      }

      .el-table--border {
        border-right: 1px solid #606266;
        border-bottom: 1px solid #606266;
      }

      .el-table__row {
        font-weight: 600;
      }

      .el-table td,
      .el-table th.is-leaf {
        border-color: #606266;
      }

      .el-table--border,
      .el-table--group {
        border-color: #606266;
      }

      .last {
        border-right: none;
      }
    }
    @media print {
      .sheets > :not(.sheet) {
        display: none;
      }

      .sheet {
        width: 275mm;
        margin: 10mm;
        margin-top: 50mm;
      }

      .el-table {
        width: 839px;
        margin: auto;
        border: 1px solid #606266;
        .el-table__header {
          font-size: 20px;
        }
        .el-table__row {
          font-size: 18px;
        }
      }
    }

    /* for preview */
    @media screen {
      .sheet {
        width: 275mm;
        margin: auto;
        margin-top: 20px;
      }
    }
  }
</style>
