import { NumberData, TableData } from '@/types/common';
import { HarnessesDataState } from '@/types/master/harness';

export const deviceStatus: NumberData = {
  0: '未設定',
  1: '準備中',
  2: '検証済',
  3: '使用中',
  4: '使用不可',
  5: '稼働停止'
};

export const deviceType: NumberData = {
  1: 'MCCS Standard 2.0',
  2: 'MCCS II 3rd gen',
  3: 'MCCS III ',
  11: 'MCCS Type T'
};

export const deviceItemId: TableData = {
  J01: 434,
  J02: 494
};

export const deviceTypeList = [
  {
    id: 1,
    name: 'MCCS Standard 2.0'
  },
  {
    id: 2,
    name: 'MCCS II 3rd gen'
  },
  {
    id: 3,
    name: 'MCCS III '
  },
  {
    id: 11,
    name: 'MCCS Type T'
  }
];

export const deviceMinorVersionList = [
  {
    name: 'J01'
  },
  {
    name: 'J02'
  }
];

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const validateSerial = (rule: any, value: string, callback: any) => {
  if (value.match(/^[A-Za-z0-9]{16}$/)) {
    return callback();
  }
  if (value.match(/^[A-Za-z0-9]{10}$/)) {
    return callback();
  }
  callback(new Error('10桁または16桁の英数字で入力してください。'));
};

export const verifyEditRule = {
  serialCode: [
    { required: true, message: '端末シリアル番号は必須です。' },
    { validator: validateSerial, trigger: 'blur' }
  ],
  firmVersion: [{ required: true, message: '出荷ファームVerは必須です。' }],
  internalBatteryVoltage: [
    { required: true, message: 'IBバッテリーは必須です。' }
  ],
  mboot: [{ required: true, message: 'Mbootは必須です。' }]
};

export const handlingType: NumberData = {
  1: '新品',
  2: '流用',
  3: 'リビルド',
  4: '中古',
  5: '不具合',
  6: '処分'
};

export const omitHandlingType: NumberData = {
  1: '新品',
  2: '流用',
  3: 'リビ',
  4: '中古',
  5: '不具合',
  6: '処分'
};

export const handlingTypeList = [
  {
    id: 1,
    name: '新品'
  },
  {
    id: 2,
    name: '流用'
  },
  {
    id: 3,
    name: 'リビルド'
  },
  {
    id: 4,
    name: '中古'
  },
  {
    id: 5,
    name: '不具合'
  },
  {
    id: 6,
    name: '処分'
  }
];

//TODO: この関数が呼ばれた時点でstoreにharnessMaster.listDataが入っていることが保証されていない。
//しかしモジュールからstore.dispatch('harnessesMaster/fetch')を呼び出せない。どうする
export const getHarnessByDeviceType = async (
  listData: HarnessesDataState[],
  harnessId: number,
  deviceType: number | null
) => {
  if (!deviceType) {
    deviceType = 2;
  }
  const foundItem: HarnessesDataState | undefined = listData.find(
    (item: HarnessesDataState) =>
      item.deviceType === deviceType && item.id === harnessId
  );
  return foundItem;
};
