import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw
} from 'vue-router';
import Login from '@/pages/Login.vue';
import Home from '@/pages/Home.vue';
import MaserList from '@/pages/master/MasterList.vue';
import InventoryList from '@/pages/inventory/InventoryList.vue';
import InventoryCreate from '@/pages/inventory/InventoryCreate.vue';
import InventoryDetail from '@/pages/inventory/InventoryDetail.vue';
import InventoryPrint from '@/pages/inventory/InventoryPrint.vue';
import SubCategoryList from '@/pages/master/subCategory/SubCategoryList.vue';
import SubCategoryCreate from '@/pages/master/subCategory/SubCategoryCreate.vue';
import SubCategoryEdit from '@/pages/master/subCategory/SubCategoryEdit.vue';
import SubCategoryDetail from '@/pages/master/subCategory/SubCategoryDetail.vue';
import PurchaseList from '@/pages/purchase/PurchaseList.vue';
import PurchaseCreate from '@/pages/purchase/PurchaseCreate.vue';
import PurchaseDetail from '@/pages/purchase/PurchaseDetail.vue';
import PurchaseEdit from '@/pages/purchase/PurchaseEdit.vue';
import ItemList from '@/pages/master/item/ItemList.vue';
import ItemCreate from '@/pages/master/item/ItemCreate.vue';
import ItemDetail from '@/pages/master/item/ItemDetail.vue';
import ItemEdit from '@/pages/master/item/ItemEdit.vue';
import CarInfoList from '@/pages/master/carInfo/CarInfoList.vue';
import CarInfoDetail from '@/pages/master/carInfo/CarInfoDetail.vue';
import CarMakerList from '@/pages/master/carMaker/CarMakerList.vue';
import CompanyList from '@/pages/master/company/CompanyList.vue';
import CompanyCreate from '@/pages/master/company/CompanyCreate.vue';
import CompanyDetail from '@/pages/master/company/CompanyDetail.vue';
import CompanyEdit from '@/pages/master/company/CompanyEdit.vue';
import CompanyGroupList from '@/pages/master/companyGroup/CompanyGroupList.vue';
import CompanyGroupCreate from '@/pages/master/companyGroup/CompanyGroupCreate.vue';
import CompanyGroupDetail from '@/pages/master/companyGroup/CompanyGroupDetail.vue';
import CompanyGroupEdit from '@/pages/master/companyGroup/CompanyGroupEdit.vue';
import FinanceList from '@/pages/master/finance/FinanceList.vue';
import FinanceCreate from '@/pages/master/finance/FinanceCreate.vue';
import FinanceDetail from '@/pages/master/finance/FinanceDetail.vue';
import FinanceEdit from '@/pages/master/finance/FinanceEdit.vue';
import HarnessList from '@/pages/master/harness/HarnessList.vue';
import CarSecurityHarnessList from '@/pages/master/harness/CarSecurityHarnessList.vue';
import PackageList from '@/pages/master/package/PackageList.vue';
import PackageCreate from '@/pages/master/package/PackageCreate.vue';
import PackageDetail from '@/pages/master/package/PackageDetail.vue';
import PackageEdit from '@/pages/master/package/PackageEdit.vue';
import ProductList from '@/pages/master/product/ProductList.vue';
import ProductCreate from '@/pages/master/product/ProductCreate.vue';
import ProductDetail from '@/pages/master/product/ProductDetail.vue';
import ProductEdit from '@/pages/master/product/ProductEdit.vue';
import ShopList from '@/pages/master/shop/ShopList.vue';
import ShopCreate from '@/pages/master/shop/ShopCreate.vue';
import ShopDetail from '@/pages/master/shop/ShopDetail.vue';
import ShopEdit from '@/pages/master/shop/ShopEdit.vue';
import SupportShopList from '@/pages/master/supportShop/SupportShopList.vue';
import SupportShopCreate from '@/pages/master/supportShop/SupportShopCreate.vue';
import SupportShopDetail from '@/pages/master/supportShop/SupportShopDetail.vue';
import SupportShopEdit from '@/pages/master/supportShop/SupportShopEdit.vue';
import SupplierList from '@/pages/master/supplier/SupplierList.vue';
import SupplierCreate from '@/pages/master/supplier/SupplierCreate.vue';
import SupplierEdit from '@/pages/master/supplier/SupplierEdit.vue';
import SupplierDetail from '@/pages/master/supplier/SupplierDetail.vue';
import AcceptCreateBasic from '@/pages/accept/AcceptCreateBasic.vue';
import AcceptCreateConfirm from '@/pages/accept/AcceptCreateConfirm.vue';
import AcceptCreateProduct from '@/pages/accept/AcceptCreateProduct.vue';
import AcceptCreateShipment from '@/pages/accept/AcceptCreateShipment.vue';
import AcceptDetail from '@/pages/accept/AcceptDetail.vue';
import AcceptList from '@/pages/accept/AcceptList.vue';
import ActivationCreate from '@/pages/activation/ActivationCreate.vue';
import ActivationCreateConfirm from '@/pages/activation/ActivationCreateConfirm.vue';
import ActivationDetail from '@/pages/activation/ActivationDetail.vue';
import ActivationList from '@/pages/activation/ActivationList.vue';
import AdvanceOrderList from '@/pages/advanceOrder/AdvanceOrderList.vue';
import AdvanceOrderStockList from '@/pages/advanceOrder/AdvanceOrderStockList.vue';
import AdvanceOrderInput from '@/pages/advanceOrder/AdvanceOrderInput.vue';
import AdvanceOrderDetail from '@/pages/advanceOrder/AdvanceOrderDetail.vue';
import AdvanceOrderConfirm from '@/pages/advanceOrder/AdvanceOrderConfirm.vue';
import BulkList from '@/pages/bulk/BulkList.vue';
import BulkInput from '@/pages/bulk/BulkInput.vue';
import BulkDetail from '@/pages/bulk/BulkDetail.vue';
import BulkConfirm from '@/pages/bulk/BulkConfirm.vue';
import DeviceList from '@/pages/device/DeviceList.vue';
import DeviceEdit from '@/pages/device/DeviceEdit.vue';
import DeviceDetail from '@/pages/device/DeviceDetail.vue';
import DeviceEditConfirm from '@/pages/device/DeviceEditConfirm.vue';
import DeviceLabel from '@/pages/device/DeviceLabel.vue';
import OrderList from '@/pages/order/OrderList.vue';
import OrderCreate from '@/pages/order/OrderCreate.vue';
import OrderDetail from '@/pages/order/OrderDetail.vue';
import OrderEdit from '@/pages/order/OrderEdit.vue';
import AutobacsDeviceList from '@/pages/autobacs/AutobacsDeviceList.vue';
import AutobacsDeviceEdit from '@/pages/autobacs/AutobacsDeviceEdit.vue';
import AutobacsDeviceDetail from '@/pages/autobacs/AutobacsDeviceDetail.vue';
import AutobacsDeviceEditConfirm from '@/pages/autobacs/AutobacsDeviceEditConfirm.vue';
import AutobacsDeviceQrcode from '@/pages/autobacs/AutobacsDeviceQrcode.vue';
import CarSecurityDeviceList from '@/pages/carSecurity/CarSecurityDeviceList.vue';
import CarSecurityDeviceDetail from '@/pages/carSecurity/CarSecurityDeviceDetail.vue';
import CarSecurityDeviceEdit from '@/pages/carSecurity/CarSecurityDeviceEdit.vue';
import CarSecurityDeviceEditConfirm from '@/pages/carSecurity/CarSecurityDeviceEditConfirm.vue';
import CarSecurityDeviceQrcode2 from '@/pages/carSecurity/CarSecurityDeviceQrcode2.vue';
import CarSecurityDeviceQrcode3 from '@/pages/carSecurity/CarSecurityDeviceQrcode3.vue';
import ManagementList from '@/pages/management/ManagementList.vue';
import ManagementInput from '@/pages/management/ManagementInput.vue';
import ManagementDetail from '@/pages/management/ManagementDetail.vue';
import ManagementConfirm from '@/pages/management/ManagementConfirm.vue';
import SaleList from '@/pages/sale/SaleList.vue';
import SaleInputAccept from '@/pages/sale/SaleInputAccept.vue';
import SaleInputShip from '@/pages/sale/SaleInputShip.vue';
import SaleInputConfirm from '@/pages/sale/SaleInputConfirm.vue';
import SaleDetail from '@/pages/sale/SaleDetail.vue';
import UnrecordList from '@/pages/unrecord/UnrecordList.vue';
import UnrecordInput from '@/pages/unrecord/UnrecordInput.vue';
import UnrecordConfirm from '@/pages/unrecord/UnrecordConfirm.vue';
import UnrecordDetail from '@/pages/unrecord/UnrecordDetail.vue';
import ShipList from '@/pages/ship/ShipList.vue';
import ShipLabel from '@/pages/ship/ShipLabel.vue';
import ShipCheckList from '@/pages/ship/ShipCheckList.vue';
import ShipNote from '@/pages/ship/ShipNote.vue';
import ShipDetail from '@/pages/ship/ShipDetail.vue';
import ShipCarSecEntrySheet from '@/pages/ship/ShipCarSecEntrySheet.vue';
import ReceiveList from '@/pages/receive/ReceiveList.vue';
import ReceiveDetail from '@/pages/receive/ReceiveDetail.vue';
import ReceiveCreate from '@/pages/receive/ReceiveCreate.vue';
import ReceiveEdit from '@/pages/receive/ReceiveEdit.vue';
import ReceiveComplete from '@/pages/receive/ReceiveComplete.vue';
import UserList from '@/pages/user/UserList.vue';
import UserCreate from '@/pages/user/UserCreate.vue';
import UserDetail from '@/pages/user/UserDetail.vue';
import UserEdit from '@/pages/user/UserEdit.vue';
import ServerError from '@/pages/error/ServerError.vue';
import StockList from '@/pages/stock/StockList.vue';
import MoveList from '@/pages/move/MoveList.vue';
import MoveCreate from '@/pages/move/MoveCreate.vue';
import MoveDetail from '@/pages/move/MoveDetail.vue';
import MoveEdit from '@/pages/move/MoveEdit.vue';

import store from '@/store';
import { currentUser, signOut } from '@/libs/firebaseAuth';
import { getEnv } from '@/libs/env';

export async function authenticate(
  to: RouteLocationNormalized,
  next: NavigationGuardNext
) {
  if (to.meta.isPublic) {
    next();
    return;
  }
  if (getEnv() === 'development') {
    if (!store.getters['auth/auth'].email) {
      await store.dispatch('auth/fetch', to.path);
    }
    next();
    return;
  }
  const user = await currentUser();
  if (user) {
    if (user.email != store.getters['auth/auth'].email) {
      await store.dispatch('auth/fetch', to.path);
    }
    next();
    return;
  }
  await signOut();
  next({ path: '/login' });
}

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { isPublic: true }
  },
  {
    path: '/top',
    name: 'Home',
    component: Home,
    meta: { isPublic: false }
  },
  {
    path: '/master',
    name: 'MaserList',
    component: MaserList,
    meta: { isPublic: false }
  },
  {
    path: '/accept',
    name: 'AcceptList',
    component: AcceptList,
    meta: { isPublic: false }
  },
  {
    path: '/accept/create/basic',
    name: 'AcceptCreateBasic',
    component: AcceptCreateBasic,
    meta: { isPublic: false }
  },
  {
    path: '/accept/create/product',
    name: 'AcceptCreateProduct',
    component: AcceptCreateProduct,
    meta: { isPublic: false }
  },
  {
    path: '/accept/create/shipment',
    name: 'AcceptCreateShipment',
    component: AcceptCreateShipment,
    meta: { isPublic: false }
  },
  {
    path: '/accept/create/confirm',
    name: 'AcceptCreateConfirm',
    component: AcceptCreateConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/advance',
    name: 'AdvanceOrderList',
    component: AdvanceOrderList,
    meta: { isPublic: false }
  },
  {
    path: '/advance/stock',
    name: 'AdvanceOrderStockList',
    component: AdvanceOrderStockList,
    meta: { isPublic: false }
  },
  {
    path: '/advance/input',
    name: 'AdvanceOrderInput',
    component: AdvanceOrderInput,
    meta: { isPublic: false }
  },
  {
    path: '/advance/input/confirm',
    name: 'AdvanceOrderInputConfirm',
    component: AdvanceOrderConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/advance/:advanceOrderId',
    name: 'AdvanceOrderDetail',
    component: AdvanceOrderDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/bulk',
    name: 'BulkList',
    component: BulkList,
    meta: { isPublic: false }
  },
  {
    path: '/bulk/input',
    name: 'BulkInput',
    component: BulkInput,
    meta: { isPublic: false }
  },
  {
    path: '/bulk/confirm',
    name: 'BulkConfirm',
    component: BulkConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/bulk/:bulkId',
    name: 'BulkDetail',
    component: BulkDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/accept/:acceptId',
    name: 'AcceptDetail',
    component: AcceptDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/ship',
    name: 'ShipList',
    component: ShipList,
    meta: { isPublic: false }
  },
  {
    path: '/ship/check/:shipId',
    name: 'ShipCheckList',
    component: ShipCheckList,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/ship/note/:shipId',
    name: 'ShipNote',
    component: ShipNote,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/ship/label/:shipId',
    name: 'ShipLabel',
    component: ShipLabel,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/ship/:shipId',
    name: 'ShipDetail',
    component: ShipDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/ship/carsecqr',
    name: 'ShipCarSecEntrySheet',
    component: ShipCarSecEntrySheet,
    meta: { isPublic: false }
  },
  {
    path: '/management',
    name: 'ManagementList',
    component: ManagementList,
    meta: { isPublic: false }
  },
  {
    path: '/management/edit',
    name: 'ManagementInput',
    component: ManagementInput,
    meta: { isPublic: false }
  },
  {
    path: '/management/edit/confirm',
    name: 'ManagementConfirm',
    component: ManagementConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/management/:customerId',
    name: 'ManagementDetail',
    component: ManagementDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/device/:MCCSver', //TODO: MCCSver in query
    name: 'DeviceList',
    component: DeviceList,
    meta: { isPublic: false }
  },
  {
    path: '/device/edit',
    name: 'DeviceEdit',
    component: DeviceEdit,
    meta: { isPublic: false }
  },
  {
    path: '/device/edit/confirm',
    name: 'DeviceEditConfirm',
    component: DeviceEditConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/device/:deviceId/detail',
    name: 'DeviceDetail',
    component: DeviceDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/device/label',
    name: 'DeviceLabel',
    component: DeviceLabel,
    meta: { isPublic: false }
  },
  {
    path: '/autobacs/device',
    name: 'AutobacsDeviceList',
    component: AutobacsDeviceList,
    meta: { isPublic: false }
  },
  {
    path: '/order',
    name: 'OrderList',
    component: OrderList,
    meta: { isPublic: false }
  },
  {
    path: '/order/create',
    name: 'OrderCreate',
    component: OrderCreate,
    meta: { isPublic: false }
  },
  {
    path: '/order/:orderId',
    name: 'OrderDetail',
    component: OrderDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/order/edit',
    name: 'OrderEdit',
    component: OrderEdit,
    meta: { isPublic: false }
  },
  {
    path: '/autobacs/device',
    name: 'AutobacsDeviceList',
    component: AutobacsDeviceList,
    meta: { isPublic: false }
  },
  {
    path: '/autobacs/device/edit',
    name: 'AutobacsDeviceEdit',
    component: AutobacsDeviceEdit,
    meta: { isPublic: false }
  },
  {
    path: '/autobacs/device/edit/confirm',
    name: 'AutobacsDeviceEditConfirm',
    component: AutobacsDeviceEditConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/autobacs/device/:deviceId',
    name: 'AutobacsDeviceDetail',
    component: AutobacsDeviceDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/autobacs/device/qrcode',
    name: 'AutobacsDeviceQrcode',
    component: AutobacsDeviceQrcode,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurity/device/:deviceType',
    name: 'CarSecurityDeviceList',
    component: CarSecurityDeviceList,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurity/device/detail/:deviceId',
    name: 'CarSecurityDeviceDetail',
    component: CarSecurityDeviceDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurity/device/detail/edit',
    name: 'CarSecurityDeviceEdit',
    component: CarSecurityDeviceEdit,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurity/device/detail/edit/confirm',
    name: 'CarSecurityDeviceEditConfirm',
    component: CarSecurityDeviceEditConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurity/device/qrcode2',
    name: 'CarSecurityDeviceQrcode2',
    component: CarSecurityDeviceQrcode2,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurity/device/qrcode3',
    name: 'CarSecurityDeviceQrcode3',
    component: CarSecurityDeviceQrcode3,
    meta: { isPublic: false }
  },
  {
    path: '/receive',
    name: 'ReceiveList',
    component: ReceiveList,
    meta: { isPublic: false }
  },
  {
    path: '/receive/create',
    name: 'ReceiveCreate',
    component: ReceiveCreate,
    meta: { isPublic: false }
  },
  {
    path: '/receive/edit',
    name: 'ReceiveEdit',
    component: ReceiveEdit,
    meta: { isPublic: false }
  },
  {
    path: '/receive/complete',
    name: 'ReceiveComplete',
    component: ReceiveComplete,
    meta: { isPublic: false }
  },
  {
    path: '/receive/:receiveId',
    name: 'ReceiveDetail',
    component: ReceiveDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/subCategory',
    name: 'SubCategoryList',
    component: SubCategoryList,
    meta: { isPublic: false }
  },
  {
    path: '/master/subCategory/create',
    name: 'SubCategoryCreate',
    component: SubCategoryCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/subCategory/edit',
    name: 'SubCategoryEdit',
    component: SubCategoryEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/subCategory/:subCategoryMasterId',
    name: 'SubCategoryDetail',
    component: SubCategoryDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/purchase',
    name: 'PurchaseList',
    component: PurchaseList,
    meta: { isPublic: false }
  },
  {
    path: '/purchase/create',
    name: 'PurchaseCreate',
    component: PurchaseCreate,
    meta: { isPublic: false }
  },
  {
    path: '/purchase/:purchaseId',
    name: 'PurchaseDetail',
    component: PurchaseDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/purchase/edit',
    name: 'PurchaseEdit',
    component: PurchaseEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/item',
    name: 'ItemList',
    component: ItemList,
    meta: { isPublic: false }
  },
  {
    path: '/master/item/create',
    name: 'ItemCreate',
    component: ItemCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/item/edit',
    name: 'ItemEdit',
    component: ItemEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/item/:itemMasterId',
    name: 'ItemDetail',
    component: ItemDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/finance',
    name: 'FinanceList',
    component: FinanceList,
    meta: { isPublic: false }
  },
  {
    path: '/master/finance/create',
    name: 'FinanceCreate',
    component: FinanceCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/finance/edit',
    name: 'FinanceEdit',
    component: FinanceEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/finance/:financeMasterId',
    name: 'FinanceDetail',
    component: FinanceDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/package',
    name: 'PackageList',
    component: PackageList,
    meta: { isPublic: false }
  },
  {
    path: '/master/package/create',
    name: 'PackageCreate',
    component: PackageCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/package/edit',
    name: 'PackageEdit',
    component: PackageEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/package/:packageMasterId',
    name: 'PackageDetail',
    component: PackageDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/product',
    name: 'ProductList',
    component: ProductList,
    meta: { isPublic: false }
  },
  {
    path: '/master/product/create',
    name: 'ProductCreate',
    component: ProductCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/product/edit',
    name: 'ProductEdit',
    component: ProductEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/product/:productMasterId',
    name: 'ProductDetail',
    component: ProductDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/shop',
    name: 'ShopList',
    component: ShopList,
    meta: { isPublic: false }
  },
  {
    path: '/master/shop/create',
    name: 'ShopCreate',
    component: ShopCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/shop/edit',
    name: 'ShopEdit',
    component: ShopEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/shop/:shopId',
    name: 'ShopDetail',
    component: ShopDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/supportshop',
    name: 'SupportShopList',
    component: SupportShopList,
    meta: { isPublic: false }
  },
  {
    path: '/master/supportshop/create',
    name: 'SupportShopCreate',
    component: SupportShopCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/supportshop/edit',
    name: 'SupportShopEdit',
    component: SupportShopEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/supportshop/:supportShopId',
    name: 'SupportShopDetail',
    component: SupportShopDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/supplier',
    name: 'SupplierList',
    component: SupplierList,
    meta: { isPublic: false }
  },
  {
    path: '/master/supplier/create',
    name: 'SupplierCreate',
    component: SupplierCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/supplier/edit',
    name: 'SupplierEdit',
    component: SupplierEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/supplier/:supplierId',
    name: 'SupplierDetail',
    component: SupplierDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/carinfo',
    name: 'CarInfoList',
    component: CarInfoList,
    meta: { isPublic: false }
  },
  {
    path: '/master/carinfo/:carInfoId',
    name: 'CarInfoDetail',
    component: CarInfoDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/carmaker',
    name: 'CarMakerList',
    component: CarMakerList,
    meta: { isPublic: false }
  },
  {
    path: '/harness',
    name: 'HarnessList',
    component: HarnessList,
    meta: { isPublic: false }
  },
  {
    path: '/carSecurityHarness',
    name: 'CarSecurityHarnessList',
    component: CarSecurityHarnessList,
    meta: { isPublic: false }
  },
  {
    path: '/master/company',
    name: 'CompanyList',
    component: CompanyList,
    meta: { isPublic: false }
  },
  {
    path: '/master/company/create',
    name: 'CompanyCreate',
    component: CompanyCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/company/edit',
    name: 'CompanyEdit',
    component: CompanyEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/company/:companyId',
    name: 'CompanyDetail',
    component: CompanyDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/master/companygroup',
    name: 'CompanyGroupList',
    component: CompanyGroupList,
    meta: { isPublic: false }
  },
  {
    path: '/master/companygroup/create',
    name: 'CompanyGroupCreate',
    component: CompanyGroupCreate,
    meta: { isPublic: false }
  },
  {
    path: '/master/companygroup/edit',
    name: 'CompanyGroupEdit',
    component: CompanyGroupEdit,
    meta: { isPublic: false }
  },
  {
    path: '/master/companygroup/:companyGroupId',
    name: 'CompanyGroupDetail',
    component: CompanyGroupDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/user',
    name: 'UserList',
    component: UserList,
    meta: { isPublic: false }
  },
  {
    path: '/user/create',
    name: 'UserCreate',
    component: UserCreate,
    meta: { isPublic: false }
  },
  {
    path: '/user/edit',
    name: 'UserEdit',
    component: UserEdit,
    meta: { isPublic: false }
  },
  {
    path: '/user/:userId',
    name: 'UserDetail',
    component: UserDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/sale',
    name: 'SaleList',
    component: SaleList,
    meta: { isPublic: false }
  },
  {
    path: '/sale/input/accept',
    name: 'SaleInputAccept',
    component: SaleInputAccept,
    meta: { isPublic: false }
  },
  {
    path: '/sale/input/ship',
    name: 'SaleInputShip',
    component: SaleInputShip,
    meta: { isPublic: false }
  },
  {
    path: '/sale/input/confirm',
    name: 'SaleInputConfirm',
    component: SaleInputConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/sale/:saleId',
    name: 'SaleDetail',
    component: SaleDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/unrecord',
    name: 'UnrecordList',
    component: UnrecordList,
    meta: { isPublic: false }
  },
  {
    path: '/unrecord/create/input',
    name: 'UnrecordCreateInput',
    component: UnrecordInput,
    meta: { isPublic: false }
  },
  {
    path: '/unrecord/create/confirm',
    name: 'UnrecordCreateConfirm',
    component: UnrecordConfirm,
    meta: { isPublic: false }
  },
  {
    path: '/unrecord/:unrecordId',
    name: 'UnrecordDetail',
    component: UnrecordDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/activation/',
    name: 'ActivationList',
    component: ActivationList,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/activation/create',
    name: 'ActivationCreate',
    component: ActivationCreate,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/activation/create/confirm',
    name: 'ActivationCreateConfirm',
    component: ActivationCreateConfirm,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/activation/detail',
    name: 'ActivationDetail',
    component: ActivationDetail,
    meta: { isPublic: false }
  },
  {
    path: '/inventory',
    name: 'InventoryList',
    component: InventoryList,
    meta: { isPublic: false }
  },
  {
    path: '/inventory/create',
    name: 'InventoryCreate',
    component: InventoryCreate,
    meta: { isPublic: false }
  },
  {
    path: '/inventory/detail',
    name: 'InventoryDetail',
    component: InventoryDetail,
    meta: { isPublic: false }
  },
  {
    path: '/inventory/print',
    name: 'InventoryPrint',
    component: InventoryPrint,
    meta: { isPublic: false }
  },
  {
    path: '/stock',
    name: 'StockList',
    component: StockList,
    meta: { isPublic: false }
  },
  {
    path: '/move',
    name: 'MovesList',
    component: MoveList,
    meta: { isPublic: false }
  },
  {
    path: '/move/create',
    name: 'MoveCreate',
    component: MoveCreate,
    meta: { isPublic: false }
  },
  {
    path: '/move/:moveId',
    name: 'MoveDetail',
    component: MoveDetail,
    props: true,
    meta: { isPublic: false }
  },
  {
    path: '/move/edit',
    name: 'MoveEdit',
    component: MoveEdit,
    meta: { isPublic: false }
  },
  // not matched path
  {
    path: '/:catchAll(.*)',
    redirect: '/top'
  },
  // // server error
  {
    path: '/error/server',
    name: 'ServerError',
    component: ServerError,
    meta: { isPublic: true }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

router.beforeEach((to, from, next) => {
  authenticate(to, next);
});

export default router;
