<template>
  <BaseSimpleLayout class="master-harness-list">
    <template #title>ハーネス適合表</template>
    <template v-if="loadingFlag" #button-area>
      <!-- <el-button
        v-if="techRole"
        type="success"
        size="large"
        @click="updateMaster"
      >
        マスタ更新
      </el-button> -->
      <input
        v-if="techRole"
        type="file"
        ref="fileInput"
        @change="handleFileSelect"
        accept=".csv"
      />
      <el-button v-if="techRole" type="success" size="large" @click="updateCSV">
        CSV取り込み
      </el-button>
      <div class="last-update">
        {{ '最終更新日:' + lastUpdate }}
      </div>
    </template>
    <template v-if="loadingFlag" #main>
      <div class="harness-list-mccs-ver-area">
        <el-form-item label="MCCSver">
          <el-radio-group v-model="selectMCCS">
            <el-radio :label="3" v-on:change="filterHarnessList">3</el-radio>
            <el-radio :label="2" v-on:change="filterHarnessList">2</el-radio>
            <el-radio :label="11" v-on:change="filterHarnessList"
              >Type T</el-radio
            >
          </el-radio-group>
        </el-form-item>
      </div>
      <div class="harness-list-filter-area">
        <span>メーカー:</span>
        <el-select
          v-model="makerId"
          filterable
          clearable
          placeholder="選択"
          class="harness-list-filter-input-maker"
          @change="filterHarnessList"
        >
          <el-option
            v-for="item in carMakerList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
        <span>車種:</span>
        <el-input
          v-model="carName"
          clearable
          placeholder="検索"
          class="harness-list-filter-input-name"
          @input="filterHarnessList"
        />
        <span>型式:</span>
        <el-input
          v-model="carModel"
          clearable
          placeholder="検索"
          class="harness-list-filter-input-model"
          @input="filterHarnessList"
        />
      </div>
      <el-table
        :data="tableData"
        stripe
        border
        :max-height="tableHeight"
        empty-text="メーカー、車種、型式いずれかを選択してください"
      >
        <el-table-column property="id" label="ID" align="center" width="80" />
        <el-table-column label="メーカー" align="center" width="100">
          <template #default="scope">
            {{
              carMakerReplaceData[
                carInfoReplaceData[scope.row.carInfoId].carMakerId
              ]
            }}
          </template>
        </el-table-column>
        <el-table-column
          property="carModelCode"
          label="指定番号"
          align="center"
          width="90"
        />
        <!-- <el-table-column label="指定番号" align="center" width="90">
          <template #default="scope">
            {{ carInfoReplaceData[scope.row.carInfoId].modelCode }}
          </template>
        </el-table-column> -->
        <el-table-column label="車種">
          <template #default="scope">
            {{ carInfoReplaceData[scope.row.carInfoId].name }}
          </template>
        </el-table-column>
        <el-table-column label="型式" align="center" width="120">
          <template #default="scope">
            {{ carInfoReplaceData[scope.row.carInfoId].model }}
          </template>
        </el-table-column>
        <el-table-column
          property="carModelType"
          label="ﾓﾃﾞﾙ区分"
          align="center"
          width="180"
        />
        <!-- <el-table-column label="ﾓﾃﾞﾙ区分" align="center" width="170">
          <template #default="scope">
            {{ carInfoReplaceData[scope.row.carInfoId].modelType }}
          </template>
        </el-table-column> -->
        <el-table-column
          property="carModelYear"
          label="年式"
          align="center"
          width="90"
        />
        <!-- <el-table-column label="年式" align="center" width="90">
          <template #default="scope">
            {{ carInfoReplaceData[scope.row.carInfoId].modelYear }}
          </template>
        </el-table-column> -->
        <el-table-column
          property="carEndSales"
          label="終了"
          align="center"
          width="90"
        />
        <!-- <el-table-column label="終了" align="center" width="90">
          <template #default="scope">
            {{ carInfoReplaceData[scope.row.carInfoId].endSales }}
          </template>
        </el-table-column> -->
        <el-table-column label="スタート方式" align="center" width="120">
          <template #default="scope">
            {{ ignitionType[scope.row.ignitionType] }}
          </template>
        </el-table-column>
        <el-table-column
          property="matchKinds"
          label="適合区分"
          align="center"
          width="90"
        />
        <el-table-column label="適合判定" align="center" width="100">
          <template #default="scope">
            {{ compatibility[scope.row.compatibilityResultJudge] }}
          </template>
        </el-table-column>
        <el-table-column
          label="ハーネス名称"
          align="center"
          property="itemName"
          width="110"
        />
        <el-table-column label="資料" align="center" width="100">
          <template #default="scope">
            <span
              v-if="displayDocumentButton(scope.row.compatibilityResultJudge)"
            >
              <el-button
                :icon="Document"
                size="small"
                type="primary"
                @click="dialogOpen(scope.row)"
              />
            </span>
            <span v-else>-</span>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog v-model="dialogFormVisible" :title="dialogTitle" width="700px">
        <div>
          <el-button
            type="primary"
            :disabled="!vehicleInformation"
            @click="openDocument(vehicleInformation)"
          >
            車種情報
          </el-button>
          <el-button
            type="primary"
            :disabled="!startCircuit"
            @click="openDocument(startCircuit)"
          >
            スタート回路
          </el-button>
          <el-button
            type="primary"
            :disabled="!brakeCircuit"
            @click="openDocument(brakeCircuit)"
          >
            ブレーキ回路
          </el-button>
          <el-button
            type="primary"
            :disabled="!audioCircuit"
            @click="openDocument(audioCircuit)"
          >
            オーディオ回路
          </el-button>
        </div>
        <div class="button-line-bottom">
          <el-button
            type="primary"
            :disabled="!earthCircuit"
            @click="openDocument(earthCircuit)"
          >
            アース回路
          </el-button>
          <el-button
            type="primary"
            :disabled="!mountingManual"
            @click="openDocument(mountingManual)"
          >
            取付マニュアル
          </el-button>
          <el-button
            type="primary"
            :disabled="!spare1"
            @click="openDocument(spare1)"
          >
            予備資料1
          </el-button>
          <el-button
            type="primary"
            :disabled="!spare2"
            @click="openDocument(spare2)"
          >
            予備資料2
          </el-button>
        </div>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>
<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import { HarnessesDataState } from '@/types/master/harness';
  import { ignitionType, compatibility } from '@/libs/harness';
  import { ElLoading } from 'element-plus';
  import { TableData } from '@/types/common';
  import {
    CarMakersDataState,
    CarMakerReplaceData
  } from '@/types/master/carMaker';
  import {
    CarInfoListDataState,
    CarInfoReplaceData
  } from '@/types/master/carInfo';
  import { rolePermission } from '@/libs/auth';
  import { Document } from '@element-plus/icons-vue';
  import { formatDate } from '@/libs/dateFormat';

  const store = useStore();
  const tableData = ref<TableData[]>([]);
  const carMakerList = ref<CarMakersDataState[]>([]);
  const carInfoList = ref<CarInfoListDataState[]>([]);
  const carMakerReplaceData = ref<CarMakerReplaceData>({});
  const carInfoReplaceData = ref<CarInfoReplaceData>({});
  const makerId = ref<number | null>(null);
  const selectMCCS = ref<number>(3);
  const carName = ref<string | null>(null);
  const carModel = ref<string | null>(null);
  const loadingFlag = ref(false);
  const dialogFormVisible = ref(false);
  const dialogTitle = ref('');
  const vehicleInformation = ref<string | null>(null);
  const techRole = ref<boolean>(rolePermission(['TECH']));
  const startCircuit = ref<string | null>(null);
  const brakeCircuit = ref<string | null>(null);
  const audioCircuit = ref<string | null>(null);
  const earthCircuit = ref<string | null>(null);
  const mountingManual = ref<string | null>(null);
  const spare1 = ref<string | null>(null);
  const spare2 = ref<string | null>(null);
  const technicalDocument = ref<string | null>(null);
  const tableHeight = ref(600);
  const lastUpdate = ref('-');
  let csvData: string | undefined;

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('carInfoListMaster/fetch'),
      store.dispatch('carMakersMaster/fetch'),
      store.dispatch('harnessesMaster/fetchLastDate')
    ]);
    carMakerList.value = store.state.carMakersMaster.listData;
    carInfoList.value = store.state.carInfoListMaster.listData;
    carMakerReplaceData.value = store.state.carMakersMaster.replaceData;
    carInfoReplaceData.value = store.state.carInfoListMaster.replaceData;
    tableData.value = [];
    tableHeight.value = window.innerHeight - 180;
    const lastDate = store.state.harnessesMaster.lastDate;
    if (lastDate) {
      lastUpdate.value = formatDate(Number(lastDate));
    }
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const filterHarnessList = async () => {
    if (!makerId.value && !carName.value && !carModel.value) {
      return (tableData.value = []);
    }
    tableData.value = store.state.harnessesMaster.listData;
    if (!tableData.value.length) {
      const loadingInstance = ElLoading.service({ fullscreen: true });
      await store.dispatch('harnessesMaster/fetch');
      loadingInstance.close();
    }
    tableData.value = store.state.harnessesMaster.listData.filter(
      (data: HarnessesDataState) => {
        let makerResult = !makerId.value;
        let nameResult = !carName.value;
        let modelResult = !carModel.value;
        const deviceTypeResult = data.deviceType === selectMCCS.value;

        if (makerId.value) {
          makerResult =
            carInfoReplaceData.value[data.carInfoId].carMakerId ===
            makerId.value;
        }
        if (carName.value) {
          if (carInfoReplaceData.value[data.carInfoId].name) {
            nameResult = carInfoReplaceData.value[data.carInfoId].name.includes(
              carName.value
            );
          }
        }
        if (carModel.value) {
          if (carInfoReplaceData.value[data.carInfoId].model) {
            modelResult = carInfoReplaceData.value[
              data.carInfoId
            ].model.includes(carModel.value);
          }
        }
        return makerResult && nameResult && modelResult && deviceTypeResult;
      }
    );
  };

  // const updateMaster = async () => {
  //   await store.dispatch('harnessesMaster/update');
  //   return (tableData.value = []);
  // };

  const handleFileSelect = (event: Event) => {
    csvData = '';
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const file = input.files[0];
      const reader = new FileReader();
      reader.onload = function (event) {
        const content = (event?.target?.result as string) || '';
        // ダブルクォートで囲まれた項目内の改行を削除する
        const contentWithoutNewLinesInQuotes = content.replace(
          /"([^"]*)"/g,
          (match, p1) => {
            return `"${p1.replace(/\r\n|\n|\r/g, ' ')}"`;
          }
        );
        csvData = contentWithoutNewLinesInQuotes;
      };
      reader.readAsText(file);
    }
  };

  const updateCSV = async () => {
    if (!csvData) {
      return;
    }
    const lines = csvData.split('\n');
    const header = lines[0].replace(/\r/g, '').split(',');
    const jsonData = [];
    for (let i = 1; i < lines.length; i++) {
      const data = lines[i].split(/,(?=(?:[^"]*"[^"]*")*[^"]*$)/);
      if (data) {
        const obj: {
          [key: string]: string;
        } = {};
        for (let j = 0; j < header.length; j++) {
          if (data[j]) {
            obj[header[j]] = data[j].replace(/"|\r\n|\n|\r/g, '');
          } else {
            obj[header[j]] = '';
          }
        }
        jsonData.push(obj);
      }
    }

    try {
      store.dispatch('harnessesMaster/csvUpload', jsonData);
    } catch (error) {
      console.error('ファイルのアップロードエラー:', error);
    }
  };

  const displayDocumentButton = (judge: number) => {
    return 0 < judge && judge < 5;
  };

  const dialogOpen = (data: HarnessesDataState) => {
    dialogFormVisible.value = true;
    dialogTitle.value =
      '資料一覧 ' +
      carInfoReplaceData.value[data.carInfoId].name +
      '(' +
      carInfoReplaceData.value[data.carInfoId].model +
      ')';
    vehicleInformation.value = data.vehicleInformationURL;
    startCircuit.value = data.startCircuitURL;
    brakeCircuit.value = data.brakeCircuitURL;
    audioCircuit.value = data.audioCircuitURL;
    earthCircuit.value = data.earthCircuitURL;
    mountingManual.value = data.mountingManualURL;
    spare1.value = data.spare1URL;
    spare2.value = data.spare2URL;
    technicalDocument.value = data.technicalDocumentURL;
  };

  const openDocument = (url: string | null) => {
    if (!url) return;
    window.open(url, '_blank');
  };
  initialize();
</script>
<style lang="scss">
  .master-harness-list {
    .main-container {
      width: 98%;
    }
    .header-container {
      height: 50px;
    }
    .last-update {
      margin: 0 3px;
    }
    .harness-list-mccs-ver-area {
      font-weight: 600;
      display: flex;
      justify-content: center;
      margin-left: auto;
      margin-right: auto;
      width: 30%;
    }

    .harness-list-filter-area {
      margin: 20px 0px;

      span {
        font-weight: 600;
        margin-left: 20px;
      }

      .el-select {
        float: initial;
      }
    }

    .harness-list-filter-input-maker {
      margin: 0px 10px;
      width: 150px;
    }

    .harness-list-filter-input-name {
      margin: 0px 10px;
      width: 400px;
    }

    .harness-list-filter-input-model {
      margin: 0px 10px;
      width: 200px;
    }

    .el-button {
      font-weight: 600;
    }

    .el-table {
      margin: 10px 0 0;
      thead th {
        background: #f5f7fa;
      }
    }

    .el-dialog__title {
      font-weight: 600;
    }

    .button-line-bottom {
      margin-top: 30px;
    }
  }
</style>
