<template>
  <BaseSimpleLayout class="product-master-detail">
    <template #title>製品詳細</template>
    <template v-if="loadingFlag" #main>
      <div class="table-title">基本情報</div>
      <BaseDataTable
        class="product-detail-table"
        :table-data="detailTableData"
        :label-width="130"
      />
      <BaseItemList :table-data="packageTableData" />
      <el-button
        type="info"
        size="large"
        @click="router.push('/master/product')"
      >
        戻る
      </el-button>
      <el-button
        v-if="editRole"
        type="success"
        size="large"
        @click="router.push('/master/product/edit')"
      >
        編集
      </el-button>
      <el-button
        v-if="deleteRole"
        type="danger"
        size="large"
        @click="dialogVisible = true"
      >
        削除
      </el-button>
      <el-dialog v-model="dialogVisible" title="確認" width="30%">
        <span class="unit-dialog-text">
          <b>この製品マスタを削除しますか。</b>
        </span>
        <template #footer>
          <el-button size="large" @click="dialogVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="danger" @click="deleteItem">
            削除
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import BaseDataTable from '@/components/BaseDataTable.vue';
  import BaseItemList from '@/components/BaseItemList.vue';
  import { productType, deviceType } from '@/libs/product';
  import { ElLoading } from 'element-plus';
  import { rolePermission } from '@/libs/auth';
  import { DetailTableData, ItemListData } from '@/types/common';
  import { PackageListData } from '@/types/master/product';

  const props = defineProps({
    productMasterId: {
      type: String,
      required: true
    }
  });
  const store = useStore();
  const detailTableData = ref<DetailTableData[]>([]);
  const packageTableData = ref<ItemListData[]>([]);
  const dialogVisible = ref(false);
  const deleteItem = () => {
    store.dispatch('productMaster/delete', props.productMasterId);
  };
  const loadingFlag = ref(false);
  const editRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));
  const deleteRole = ref(rolePermission(['ORDERING', 'ORDERADMIN']));

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await Promise.all([
      store.dispatch('productMaster/fetch', props.productMasterId),
      store.dispatch('packagesMaster/fetch')
    ]);
    const detailData = store.state.productMaster.detailData;
    const packageDataChanger = store.state.packagesMaster.replaceData;
    detailTableData.value = [
      {
        label: 'ID',
        value: detailData.id
      },
      {
        label: '製品名称',
        value: detailData.name
      },
      {
        label: '品番',
        value: detailData.productCode
      },
      {
        label: 'JAN',
        value: detailData.jan
      },
      {
        label: '製品種別',
        value: productType[detailData.productType]
      },
      {
        label: '製品価格(円)',
        value: detailData.price
      },
      {
        label: 'MCCS ver',
        value: deviceType[detailData.deviceType]
      }
    ];
    packageTableData.value = detailData.details.map((data: PackageListData) => {
      return {
        id: data.packId,
        name: packageDataChanger[data.packId],
        qty: data.packQty
      };
    });
    loadingInstance.close();
    loadingFlag.value = true;
  };
  initialize();
</script>
<style lang="scss">
  .product-master-detail {
    .table-title {
      font-size: 20px;
      font-weight: 600;
      margin-top: 20px;
    }
    .product-detail-table {
      width: 500px;
    }
    .el-table {
      margin: auto;
    }
    .el-button {
      margin: 20px 10px;
      font-weight: 600;
    }
  }
</style>
