<template>
  <BaseSimpleLayout class="prodcut-master-create">
    <template #title>製品登録</template>
    <template v-if="loadingFlag" #main>
      <el-form
        ref="formRef"
        :model="createForm"
        :rules="rules"
        size="large"
        label-width="100px"
      >
        <el-form-item
          class="unit-master-product-create-name"
          label="製品名称"
          prop="name"
        >
          <el-input
            v-model="createForm.name"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-product-create-productCode"
          label="品番"
          prop="productCode"
        >
          <el-input
            v-model="createForm.productCode"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          class="unit-master-product-create-jan"
          label="JAN"
          prop="jan"
        >
          <el-input
            v-model="createForm.jan"
            autocomplete="off"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          label="製品種別"
          prop="productType"
          class="product-input-type"
        >
          <el-select
            v-model="createForm.productType"
            placeholder="選択"
            :disabled="isConfirm"
          >
            <el-option
              v-for="productype in productTypeList"
              :key="productype.val"
              :label="productype.label"
              :value="productype.val"
            />
          </el-select>
        </el-form-item>
        <el-form-item class="product-input-price" label="費用(円)" prop="price">
          <el-input-number
            v-model="createForm.price"
            :precision="0"
            :min="0"
            :controls="false"
            :disabled="isConfirm"
          />
        </el-form-item>
        <el-form-item
          label="MCCS ver"
          prop="MCCSver"
          class="product-input-type"
        >
          <el-select
            v-model="createForm.deviceType"
            placeholder="選択"
            :disabled="isConfirm"
          >
            <el-option
              v-for="devicetype in deviceTypeList2"
              :key="devicetype.val"
              :label="devicetype.label"
              :value="devicetype.val"
            />
          </el-select>
        </el-form-item>
      </el-form>
      <el-button
        v-if="createForm.productType < 3"
        type="success"
        size="large"
        class="product-create-button-add-package"
        :disabled="isConfirm"
        @click="dialogFormVisible = true"
      >
        パッケージ追加
      </el-button>
      <el-table
        v-if="createForm.productType < 3"
        :data="createForm.details"
        stripe
        border
        style="width: 700px"
        class="unit-master-product-create-details-table"
        empty-text="組付パッケージが存在しません"
      >
        <el-table-column
          prop="packId"
          label="ID"
          class-name="unit-master-product-details-name"
          width="60px"
          align="center"
        />
        <el-table-column
          prop="packName"
          label="パッケージ名"
          class-name="unit-master-product-details-name"
        >
          <template #default="scope">
            <span>{{ packageNameChanger[scope.row.packId] }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="packQty"
          label="個数"
          width="60px"
          align="center"
          class-name="unit-master-product-details-qty"
        />
        <el-table-column
          label="操作"
          width="85px"
          align="center"
          class-name="unit-master-product-details-ope"
        >
          <template #default="scope">
            <el-button
              type="danger"
              class="unit-master-product-details-button-delete"
              :disabled="isConfirm"
              @click="deletePackage(scope.$index)"
            >
              削除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div v-if="isConfirm" class="product-create-button-area">
        <el-button type="info" size="large" @click="isConfirm = false">
          キャンセル
        </el-button>
        <el-button type="primary" size="large" @click="submit()">
          確認
        </el-button>
      </div>
      <div v-else class="product-create-button-area">
        <el-button
          type="info"
          size="large"
          @click="router.push('/master/product')"
        >
          戻る
        </el-button>
        <el-button type="primary" size="large" @click="changeConfirm(formRef)">
          確認
        </el-button>
      </div>
      <el-dialog
        v-model="dialogFormVisible"
        title="組付パッケージ登録"
        width="500px"
      >
        <el-form
          size="large"
          :model="packageForm"
          class="product-create-details-form"
        >
          <el-form-item label="パッケージ名" label-width="130px">
            <el-select
              v-model="packageForm.packId"
              filterable
              placeholder="Select"
              class="product-create-details-id-input"
            >
              <el-option
                v-for="option in packageList"
                :key="option.id"
                :label="option.name"
                :value="option.id"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            label="個数"
            label-width="130px"
            class="product-input-pack-qty"
          >
            <el-input-number
              v-model="packageForm.packQty"
              :precision="0"
              :min="0"
              :controls="false"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <el-button size="large" @click="dialogFormVisible = false">
            キャンセル
          </el-button>
          <el-button size="large" type="primary" @click="addPackage">
            追加
          </el-button>
        </template>
      </el-dialog>
    </template>
  </BaseSimpleLayout>
</template>

<script setup lang="ts">
  import { ref } from 'vue';
  import { useStore } from 'vuex';
  import router from '@/router';
  import BaseSimpleLayout from '@/components/BaseSimpleLayout.vue';
  import type { FormInstance, FormRules } from 'element-plus';
  import {
    ProductsSubmitDataState,
    AddPackageData
  } from '@/types/master/product';
  import {
    PackagesDataState,
    PackageReplaceNameData
  } from '@/types/master/package';
  import { productTypeList, deviceTypeList2 } from '@/libs/product';
  import { ElLoading } from 'element-plus';

  const store = useStore();
  const formRef = ref<FormInstance>();
  const dialogFormVisible = ref(false);
  const createForm = ref<ProductsSubmitDataState>({
    name: '',
    productCode: '',
    jan: null,
    price: 0,
    productType: 1,
    details: [],
    deviceType: null
  });
  const packageForm = ref<AddPackageData>({
    packId: null,
    packQty: 1
  });
  const rules = ref<FormRules>({
    name: [{ required: true, message: '製品名称は必須です。' }],
    price: [{ required: true, message: '製品価格は必須です。' }],
    productType: [{ required: true, message: '製品種別は必須です。' }]
  });
  const packageList = ref<PackagesDataState[]>([]);
  const packageNameChanger = ref<PackageReplaceNameData>({});
  const loadingFlag = ref(false);
  const isConfirm = ref(false);

  const initialize = async () => {
    const loadingInstance = ElLoading.service({ fullscreen: true });
    await store.dispatch('packagesMaster/fetch');
    packageList.value = store.state.packagesMaster.listData;
    packageForm.value.packId = packageList.value[0].id;
    packageNameChanger.value = store.state.packagesMaster.replaceData;
    loadingInstance.close();
    loadingFlag.value = true;
  };
  const changeConfirm = async (formEl: FormInstance | undefined) => {
    if (!formEl) return;
    formEl.validate((valid: boolean) => {
      if (!valid) {
        return false;
      }
      if (createForm.value.productType > 2) createForm.value.details = [];
      isConfirm.value = true;
    });
  };
  const submit = () => {
    store.dispatch('productsMaster/submit', createForm.value);
  };
  const addPackage = () => {
    createForm.value.details.push(packageForm.value);
    dialogFormVisible.value = false;
    packageForm.value = {
      packId: packageList.value[0].id,
      packQty: 1
    };
  };
  const deletePackage = (index: number) => {
    createForm.value.details.splice(index, 1);
  };
  initialize();
</script>
<style lang="scss">
  .prodcut-master-create {
    .el-button {
      font-weight: 600;
    }
    form {
      width: 450px;
      margin: auto;
    }
    .el-form-item {
      font-weight: 600 !important;
    }
    .product-create-details-form {
      width: 500px;
    }

    .product-create-button-add-package {
      margin-bottom: 20px;
    }

    .product-create-details-id-input {
      float: left;
      width: 300px;
    }

    .product-create-details-qty-input {
      float: left;
      width: 65px;
    }

    .product-create-details-error-text {
      color: #f56c6c;
      font-size: 12px;
      line-height: 1;
      padding-top: 4px;
      position: absolute;
    }

    .product-create-button-area {
      margin: 10px 0;
    }

    .product-input-price {
      .el-input-number {
        float: left;
        width: 160px;
      }
      input {
        text-align: left;
      }
    }
    .product-input-type {
      .el-select {
        width: 160px;
        float: left;
      }
    }
    .product-input-pack-qty {
      .el-input-number {
        float: left;
        width: 60px;
      }
    }
    .el-table__header-wrapper {
      th {
        background-color: #f5f7fa;
      }
    }
    .el-table {
      margin: auto;
    }
    td {
      div {
        font-weight: 600;
      }
    }
  }
</style>
