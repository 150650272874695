<template>
  <router-view :key="reloadKey" />
</template>

<script setup lang="ts">
  import { ref, watch } from 'vue';
  import { useRoute } from 'vue-router';
  const route = useRoute();
  //TODO: search better method, https://github.com/global-mobility-service/sips/pull/182#discussion_r1304389382
  const reloadKey = ref(Date.now());

  // リロードが必要なページ遷移パターンをリスト化
  const reloadPaths = [
    ['/device/2', '/device/3'],
    ['/device/2', '/device/11'],
    ['/device/3', '/device/2'],
    ['/device/3', '/device/11'],
    ['/device/11', '/device/2'],
    ['/device/11', '/device/3'],
    ['/carSecurity/device/3', '/carSecurity/device/2'],
    ['/carSecurity/device/2', '/carSecurity/device/3']
  ];

  watch(
    () => route.path,
    (newPath, oldPath) => {
      if (
        reloadPaths.some(
          ([a, b]) =>
            (newPath === a && oldPath === b) || (newPath === b && oldPath === a)
        )
      ) {
        reloadKey.value = Date.now();
      }
    }
  );
</script>
<style>
  #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }

  body {
    margin: 0px;
  }
</style>
